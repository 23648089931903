import { mapActions, mapState } from 'vuex'
import { myMixin } from '../../../mixins';

export default {
  mixins: [myMixin],
  data: ()=>({
    drawer: null,
    dialog: false,
    model: null,
    desserts: [],
    searchGroup: null,
    searchAmbience: null,
    searchTeacher: null,
    hours: [],
    shifts: [],
    hourClasses: [],
    pendencies: 0,
    renderComponent: true,
    snackbar: false,
    showGrids: false,
    showSaturday: false,
    showFilterPrints: false,
    days_number: [ 2, 3, 4, 5, 6, 7 ],
    grids: null,
    group: null,
    ambience: null,
    teacher: null,
    selectedType: null,
    modelGrid: null,
    selectedGrid: null,
    loading: false,
    availabilites: [],
    loadingTeacher: false,
    dialogChatGrid: false,
    dialogChatGroup: false,
    colors: ['error', 'success', 'primary', 'purple', 'pink', 'cyan', 'indigo', 'blue', 'teal', 'amber', 'blue-grey', 'light-green', 'orange', 'brown', 'light-blue', 'deep-purple'],
    teachers: [],
    headers: [
      { text: 'Nome', value: 'name'},
      { text: 'Ações', value: 'actions', align: 'center', sortable: false}
    ],
    
  }),

  watch: {
    
  },

  mounted(){
    this.loadHours();
  },

  computed: {
    ...mapState('auth', ['acesso']),
  },

  methods:  {
    ...mapActions('hour', ['ActionGetHours']),
    ...mapActions('group', ['ActionFindGridsOptimized', 'ActionFindGroupById', 'ActionFindGroups']),
    ...mapActions('teacher', ['ActionFindTeachers']),
    ...mapActions('join', ['ActionFindJoinByGroupId']),
    ...mapActions('availability', ['ActionFindOptimized']),
    ...mapActions('teacher', ['ActionFindTeacherClassesOptimized']),

    loadHours(){
      
      let payload = {
        find_columns: {
          active: 1
        },
        with: 'groups'
      };
      
      this.ActionGetHours(payload).then((res) => {
          this.hours = res.data;
          
      });

    },

    showHour(hour){

      if(this.selectedType == 'group'){
        return this.shifts.includes(hour.shift_id) && this.hasGroups(hour);
      }else{
        return this.hourClasses.includes(hour.id);
      }
    },

    hasGroups(hour){

      if(!this.group){
        return true;
      }
      
      if(this.group.hour_group_id){
        
        var find = hour.groups.find(h => h.id == this.group.hour_group_id);

        return find != null || this.hourClasses.includes(hour.id);

      }else{
        return false;
      }

    },

    openChatGrid(item){
      this.selectedGrid = item;
      this.dialogChatGrid = true;
    },

    openExtract(item){

      this.model = item;
      this.dialog = true;

    },

    selectItem(type, item){
      
      if(this.loading){
        return;
      }
      
      this.group = null;
      this.ambience = null;
      this.teacher = null;
      this.selectedType = type;

      switch (type) {
        case 'group':
          this.group = item;
          break;
        case 'ambience':
          this.ambience = item;
          break;
        case 'teacher':
          
          this.teacher = item;
          this.teacher.hasTimeTables = false;

          this.teacher.activities.forEach(act => {
            if(act.timeTables.length){
              this.teacher.hasTimeTables = true;
              return;
            }
          });

          break;
        default:
          return;
      }

      this.grids = null;
      this.loading = true;
      this.pendencies = 0;
      this.selectedGrid = null;
      this.availabilites = [];

      this.ActionFindGridsOptimized({ type: type, item_id: item.id, noFilterTimeTable: true })
          .then((res) => {
            this.grids = res.data;
            this.loadColors(this.grids);
            this.loadShifts(this.grids);
            this.loadDays(this.grids);
            this.loadJoins(this.grids);
            this.loadDates(this.grids);
            this.updatePendencies(this.grids);
        }).finally(() => {
          this.loading = false;
          this.forceRerender();
        });

    },

    loadJoins(grids){

      if(!grids || grids.length == 0 || !grids[0] ){
        return;
      }

      var ids = '';

      grids.forEach(g => {
        if(!ids.includes(g.group.id)){
          if(ids.length > 0){
            ids += ',';
          }
          ids += g.group.id;
        }
      });

      var payload = {
        group_id: ids,
        showAllCampuses: true,
        with: "grids,grids,grids.discipline,grids.group,grids.group.campus,grids.workLoadType"
      }
      
      this.ActionFindJoinByGroupId(payload).then((res) => {
          
        
        for (let i = 0; i < grids.length; i++) {
          
          if(grids[i].join){
            var join = res.data.find(j => j.id == grids[i].join.join_id);
            if(join){
              grids[i].join.grids = join.grids;
            }
          }

        }

        this.forceRerender();
        
      });
    },

    loadDates(grids){

      var ids = '';

      grids.forEach(g => {
        if(!ids.includes(g.group.id)){
          if(ids.length > 0){
            ids += ',';
          }
          ids += g.group.id;
        }
      });

      var payload = {
        find_columns: {
          id: ids,
        },
        with: "grids.dates,grids.specificity",
      }

      this.ActionFindGroups(payload).then((res) => {
        
        res.data.forEach(group => {
          group.grids.forEach(grid => {
            var findGrid = grids.find(g => g.id == grid.id);
            if(findGrid){
              findGrid.dates = grid.dates;
            }
          });
        });
        
        this.forceRerender();
        
      });
    },

    forceRerender () {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    getTeachers(){
      
      this.loadingTeacher = true;

      let payload = {
        search: this.searchTeacher,
        showAllTeachers: true,
        find_columns: {
          active: 1,
        },
        with: 'campuses,emails,titling,resources,workRegime,activities.timeTables'
      };

      this.ActionFindTeachers(payload)
          .then((res) => {
              this.teachers = res.data;
          })
          .finally(() => {
              this.loadingTeacher = false;
          })
  },

    getGrid(day, hour){

      if(this.grids){
        let classes = this.getClasses(day, hour);

        if(classes.length > 0){
          return this.grids.find(g => g.id == classes[0].grid_id);
        }
      }

      return null;

    },

    getGrids(day, hour){

      if(this.grids){
        let classes = this.getClasses(day, hour);

        if(classes.length > 0){
          
          var ids = classes.map(o => o['grid_id']);
          return this.grids.filter(g => ids.includes(g.id));
        }
      }

      return null;

    },

    getColor(day, hour){

      let grid = this.getGrid(day, hour);

      return grid ? grid.color : '';

    },

    getPendencieGrids(){
      
      if(this.grids){
        return this.grids.filter(g => g.pendencies > 0);
      }else{
        return [];
      }
      
    },

    getClasses(day, hour){

      let classes = [];
      
      if(this.grids){

        this.grids.forEach(grid => {
          
          let find = grid.classes.filter(c => ( c.day_of_week_id == day && c.hour_id == hour.id ));
          
          if(find){
            find.forEach(f => {
              classes.push(f);
            });
           
          }
        });
        
      }

      return classes;

    },

    loadColors(grids){

      var index = 0;
          
      grids.forEach(grid => {
        
        if(index < this.colors.length -1){
          grid.color = this.colors[index];
        }else{
          grid.color = this.generateColor();
        }

        index++;

      });

    },

    loadTeacherData(grid){
        
      if(!grid.teacher){
        return;
      }

      this.loadAvailability(grid);

      if(grid.teacher.classes){
        return;
      }

      this.loading = true;

      this.ActionFindTeacherClassesOptimized({ id: grid.teacher.id })
        .then(res => {
            grid.teacher.classes = res.data;
        }).finally(() => {
          this.forceRerender();
          this.loading = false;
        });
      
    },

    loadAvailability(grid){
      
      this.availabilites = [];
      this.selectedGrid = grid;
      
      if(!grid.teacher || grid.teacher.availability){
        return;
      }

      if(grid.teacher.availability){
        this.availabilites = grid.teacher.availability;
        return;
      }

      this.loading = true;

      this.ActionFindOptimized({ teacher_id: grid.teacher.id })
        .then(res => {
          grid.teacher.availabilites = res.data;
          this.availabilites = res.data;
        }).finally(() => {
          this.forceRerender();
          this.loading = false;
        });
      
    },

    getClassCell(day, hour){

      var disponivel = false;
      var outherGroups = false;
      var disp;

      if(this.selectedGrid && this.availabilites.length > 0 && this.selectedGrid.teacher.classes){

        disp = this.getAvailability(day, hour);

        var classes = this.selectedGrid.teacher.classes.find(c => 
            (
              c.day_of_week_id == day &&
              this.isHourEqual(e.hour, hour) &&
              c.group_id != this.selectedGrid.group.id &&
              (!c.join_id || !this.selectedGrid.join || (c.join_id != this.selectedGrid.join.join_id))
            )
          );

        if(classes){
          outherGroups = true;
        }

        if(disp){
          disponivel = true;
        }else{
          disponivel = false;
        }
        
        var classCell = {
          disponivel: disponivel,
          indisponivel: outherGroups,
        };

        return classCell

      }

      return null;

    },

    getAvailability(day, hour){

      var disp = this.availabilites.find((d) => (this.isHourEqual({id: d.hour_id, start: d.start, end: d.end}, hour) && d.day_of_week_id == day));

      if(disp)
        return disp;
      else
        return null;

     
    },

    loadShifts(grids){

      this.shifts = [];
      this.hourClasses = [];
      
      if(this.selectedType == 'group'){
        this.shifts.push(this.group.shift_id);
      }else{
        this.shifts.push(1);
        this.shifts.push(2);
        this.shifts.push(3);
        this.shifts.push(4);
      }
      
      grids.forEach(grid => {
        grid.classes.forEach(classe => {
          let hour = this.hours.find(h => h.id == classe.hour_id);
          if(hour){
            
            if(!this.shifts.includes(hour.shift_id)){
              this.shifts.push(hour.shift_id);
            }

            if(!this.hourClasses.includes(hour.id)){
              this.hourClasses.push(hour.id);
            }
            
          }
        });
      })
    },

    loadDays(grids){

      this.showSaturday = false;
      
      grids.forEach(grid => {
        grid.classes.forEach(classe => {
         
          if(classe.day_of_week_id == 7){
            this.showSaturday = true;
            return;
          }

        });
      })
    },

    getNameDisciplineToolTip(grid){
              
      if(!grid){
        return;
      }  

      var name = grid.discipline.name;
      var type = grid.work_load_type;
      var period = grid.periodicity_type;

      if(type != 'TE'){
          name = type + '-' + name;
      }

      if(period != 'S'){
          name = name + ' (' + period + ')';
      }

      if(grid.group_number != 0 && grid.teacher_number != 0){
        name = 'D' + grid.group_number + '/P' + grid.teacher_number + '-' + name;
      }else if(grid.group_number != 0){
        name = 'D' + grid.group_number + '-' + name;
      }else if(grid.teacher_number != 0){
        name = 'P' + grid.teacher_number + '-' + name;
      }

      return name;

    },

    getNameDiscipline(grid, length = 12){
        
      if(!grid){
        return;
      }

      var name = grid.discipline.name;
      var type = grid.work_load_type;
      var period = grid.periodicity_type;

        if(type != 'TE'){
            name = type + '-' + name;
        }

        if(grid.group_number != 0 && grid.teacher_number != 0){
          name = 'D' + grid.group_number + '/P' + grid.teacher_number + '-' + name;
          name = this.getFirstName(name, length, true);
        }else if(grid.group_number != 0){
          name = 'D' + grid.group_number + '-' + name;
          name = this.getFirstName(name, length, true);
        }else if(grid.teacher_number != 0){
          name = 'P' + grid.teacher_number + '-' + name;
          name = this.getFirstName(name, length, true);
        }else{
          name = this.getFirstName(name, length, true);
        }

        if(period != 'S'){
            name = name + ' (' + period + ')';
        }

        return name;
    },

    getNameTeacher(grid){

      if(!grid || !grid.teacher){
        return '';
      }

      return this.getFirstName(grid.teacher.name, 15);

    },

    getFirstName(name, length, personal = false){
            
      var index = name.indexOf(' ');
      var firstName = name;

      if(index != -1){

          var parts = name.split(' ');

          if(parts.length > 1 && (parts[0].toUpperCase() == 'A' || parts[0].toUpperCase() == 'O')){
              firstName = parts[1];
          }else{
              firstName = parts[0];

              if(personal && firstName.length < length && parts.length > 1){
                firstName = parts[0] + ' ' + parts[1];
              }
          }
      }

      if(firstName.length > length)
          firstName = firstName.substring(0, length);

      return firstName;
    },

    updatePendencies(grids){
      
      grids.forEach(grid => {
        
        let workLoad = grid.work_load_time_table;
        let times = 0;

        if(grid.has_time_table){

        }
        
        grid.classes.forEach(classe => {
          
          let hour = this.hours.find(h => h.id == classe.hour_id);
          
          if(hour){
            times += hour.number_of_classes;
          }
        });

        if(grid.has_time_table){
          grid.pendencies = workLoad - times;
        }else{
          grid.pendencies = 0;
        }

        this.pendencies += grid.pendencies;

        this.showGrids = this.pendencies > 0;

      });
      
    },

    getAmbienceName(grid, day, hour){
      
      if(this.grids){
        
        let classes = this.getClasses(day, hour);

        if(classes.length > 0){
          let classes2 = classes.filter(c => c.grid_id == grid.id);
          if(classes2.length > 0 && classes2[0].ambience){
            return classes2[0].ambience.name;
          }
        }
      }

      return '';

    },

    generateColor() {
          
      const letters = '0123456789ABCDEF';
      let color = '#';
      
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      
      return color;
      
    },

    copyToClipBoard(link){
      
      if(link){
        navigator.clipboard.writeText(link);
        this.snackbar = true;
      }
      
    }
  }

}
